import {Localizations} from '~/types';

export const countries: Localizations = {
  default: {
    label: 'United Kingdom (GBP £)',
    language: 'EN',
    country: 'GB',
    currency: 'GBP',
    currencySymbol: '£',
  },
  '/en-ad': {
    label: 'Andorra (EUR €)',
    language: 'EN',
    country: 'AD',
    currency: 'EUR',
    currencySymbol: '€',
  },
  '/en-at': {
    label: 'Austria (EUR €)',
    language: 'EN',
    country: 'AT',
    currency: 'EUR',
    currencySymbol: '€',
  },
  '/en-au': {
    label: 'Australia (AUD $)',
    language: 'EN',
    country: 'AU',
    currency: 'AUD',
    currencySymbol: '$',
  },
  '/en-be': {
    label: 'Belgium (EUR €)',
    language: 'EN',
    country: 'BE',
    currency: 'EUR',
    currencySymbol: '€',
  },
  '/en-ca': {
    label: 'Canada (CAD $)',
    language: 'EN',
    country: 'CA',
    currency: 'CAD',
    currencySymbol: '$',
  },
  '/en-cn': {
    label: 'China (CNY ¥)',
    language: 'EN',
    country: 'CN',
    currency: 'CNY',
    currencySymbol: '¥',
  },
  '/en-cy': {
    label: 'Cyprus (EUR €)',
    language: 'EN',
    country: 'CY',
    currency: 'EUR',
    currencySymbol: '€',
  },
  '/en-de': {
    label: 'Germany (EUR €)',
    language: 'EN',
    country: 'DE',
    currency: 'EUR',
    currencySymbol: '€',
  },
  '/en-ee': {
    label: 'Estonia (EUR €)',
    language: 'EN',
    country: 'EE',
    currency: 'EUR',
    currencySymbol: '€',
  },
  '/en-es': {
    label: 'Spain (EUR €)',
    language: 'EN',
    country: 'ES',
    currency: 'EUR',
    currencySymbol: '€',
  },
  '/en-fi': {
    label: 'Finland (EUR €)',
    language: 'EN',
    country: 'FI',
    currency: 'EUR',
    currencySymbol: '€',
  },
  '/en-fr': {
    label: 'France (EUR €)',
    language: 'EN',
    country: 'FR',
    currency: 'EUR',
    currencySymbol: '€',
  },
  '/en-gb': {
    label: 'United Kingdom (GBP £)',
    language: 'EN',
    country: 'GB',
    currency: 'GBP',
    currencySymbol: '£',
  },
  '/en-gr': {
    label: 'Greece (EUR €)',
    language: 'EN',
    country: 'GR',
    currency: 'EUR',
    currencySymbol: '€',
  },
  '/en-id': {
    label: 'Indonesia (IDR Rp)',
    language: 'EN',
    country: 'ID',
    currency: 'IDR',
    currencySymbol: 'p',
  },
  '/en-ie': {
    label: 'Ireland (EUR €)',
    language: 'EN',
    country: 'IE',
    currency: 'EUR',
    currencySymbol: '€',
  },
  '/en-in': {
    label: 'India (INR ₹)',
    language: 'EN',
    country: 'IN',
    currency: 'INR',
    currencySymbol: '₹',
  },
  '/en-it': {
    label: 'Italy (EUR €)',
    language: 'EN',
    country: 'IT',
    currency: 'EUR',
    currencySymbol: '€',
  },
  '/en-jp': {
    label: 'Japan (JPY ¥)',
    language: 'EN',
    country: 'JP',
    currency: 'JPY',
    currencySymbol: '¥',
  },
  '/en-kr': {
    label: 'South Korea (KRW ₩)',
    language: 'EN',
    country: 'KR',
    currency: 'KRW',
    currencySymbol: '₩',
  },
  '/en-lt': {
    label: 'Lithuania (EUR €)',
    language: 'EN',
    country: 'LT',
    currency: 'EUR',
    currencySymbol: '€',
  },
  '/en-lu': {
    label: 'Luxembourg (EUR €)',
    language: 'EN',
    country: 'LU',
    currency: 'EUR',
    currencySymbol: '€',
  },
  '/en-lv': {
    label: 'Latvia (EUR €)',
    language: 'EN',
    country: 'LV',
    currency: 'EUR',
    currencySymbol: '€',
  },
  '/en-mc': {
    label: 'Monaco (EUR €)',
    language: 'EN',
    country: 'MC',
    currency: 'EUR',
    currencySymbol: '€',
  },
  '/en-me': {
    label: 'Montenegro (EUR €)',
    language: 'EN',
    country: 'ME',
    currency: 'EUR',
    currencySymbol: '€',
  },
  '/en-mt': {
    label: 'Malta (EUR €)',
    language: 'EN',
    country: 'MT',
    currency: 'EUR',
    currencySymbol: '€',
  },
  '/en-nl': {
    label: 'Netherlands (EUR €)',
    language: 'EN',
    country: 'NL',
    currency: 'EUR',
    currencySymbol: '€',
  },
  '/en-nz': {
    label: 'New Zealand (NZD $)',
    language: 'EN',
    country: 'NZ',
    currency: 'NZD',
    currencySymbol: '$',
  },
  '/en-pt': {
    label: 'Portugal (EUR €)',
    language: 'EN',
    country: 'PT',
    currency: 'EUR',
    currencySymbol: '€',
  },
  '/en-sg': {
    label: 'Singapore (SGD $)',
    language: 'EN',
    country: 'SG',
    currency: 'SGD',
    currencySymbol: '$',
  },
  '/en-si': {
    label: 'Slovenia (EUR €)',
    language: 'EN',
    country: 'SI',
    currency: 'EUR',
    currencySymbol: '€',
  },
  '/en-sk': {
    label: 'Slovakia (EUR €)',
    language: 'EN',
    country: 'SK',
    currency: 'EUR',
    currencySymbol: '€',
  },
  '/en-sm': {
    label: 'San Marino (EUR €)',
    language: 'EN',
    country: 'SM',
    currency: 'EUR',
    currencySymbol: '€',
  },
  '/en-th': {
    label: 'Thailand (THB ฿)',
    language: 'EN',
    country: 'TH',
    currency: 'THB',
    currencySymbol: '฿',
  },
  '/en-va': {
    label: 'Vatican City (EUR €)',
    language: 'EN',
    country: 'VA',
    currency: 'EUR',
    currencySymbol: '€',
  },
  '/en-vn': {
    label: 'Vietnam (VND ₫)',
    language: 'EN',
    country: 'VN',
    currency: 'VND',
    currencySymbol: '₫',
  },
  '/en-xk': {
    label: 'Kosovo (EUR €)',
    language: 'EN',
    country: 'XK',
    currency: 'EUR',
    currencySymbol: '€',
  },
};
